var render = function (_h,_vm) {var _c=_vm._c;return (_vm.props.data)?_c('div',{staticClass:"component c-championship c-championship--card"},[_c(_vm.injections.components.NavigationLink,{tag:"component",attrs:{"_class":'c-championship__url display-block text-decoration-none',"url":_vm.props.data.full_page
				? ("/championship/" + (_vm.props.data.slug))
				: _vm.$options.primary_link(_vm.props.data.primary_link)}},[_c('div',{staticClass:"display-flex\n\t\t\t\tbp-540:display-block"},[_c('div',{staticClass:"c-championship__media bg-color-light-background position-relative\n\t\t\t\t\tbp-540:aspect-ratio-16-9 bp-540:overflow-hidden"},[(_vm.props.data.image && _vm.props.data.image.url)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
						_vm.$options.get_image_resizer_url(
							("https://d230gea5qwdu36.cloudfront.net" + (_vm.props.data.image.url))
						)
					),expression:"\n\t\t\t\t\t\t$options.get_image_resizer_url(\n\t\t\t\t\t\t\t`https://d230gea5qwdu36.cloudfront.net${props.data.image.url}`,\n\t\t\t\t\t\t)\n\t\t\t\t\t"}],staticClass:"c-championship__image height-100 position-absolute top-0 left-0 object-fit-cover object-position-c transition width-100",attrs:{"alt":_vm.props.data.image.alt_text}}):_c('img',{staticClass:"c-championship__image height-100 position-absolute top-0 left-0 object-fit-cover object-position-c transition width-100",attrs:{"src":require("@/assets/placeholder.jpg"),"alt":""}}),_c('div',{staticClass:"c-championship__division display-inline-block font-size-10 text-on-primary position-absolute text-transform-uppercase"},[_vm._v(" "+_vm._s(_vm.props.data.division)+" ")])]),_c('div',{staticClass:"c-championship__details padding-16 display-flex\n\t\t\t\t\tbp-768:padding-x-0 bp-540:display-block"},[_c('div',{staticClass:"c-championship__title display-flex align-items-center"},[_c('img',{staticStyle:{"filter":"invert(36%) sepia(99%) saturate(894%) hue-rotate(169deg) brightness(94%) contrast(104%)"},attrs:{"src":require("@/assets/tickets-v2.svg"),"width":"30","height":"30","alt":""}}),_c('span',{staticClass:"flex-item-1 header-font font-size-18 line-height-100 text-transform-uppercase text-brand transition transition-timing-function-ease\n\t\t\t\t\t\t\tbp-1024:font-size-30"},[_vm._v(_vm._s(_vm.props.data.title))])])])])]),(
			_vm.props.showTileOverlayText &&
				_vm.props.data.tile_overlay_links &&
				_vm.props.data.tile_overlay_links.length > 0
		)?_c('div',{staticClass:"tileOverlayTextDesktop"},[_c('div',{staticClass:"tileOverlayTextWrapper"},_vm._l((_vm.props.data.tile_overlay_links),function(link){return _c(_vm.injections.components.NavigationLink,{key:link.id,tag:"component",attrs:{"_class":"c-championship__url display-block text-decoration-none tileOverlayTextLink","url":link.link,"_external-icon":""}},[_c('span',{domProps:{"innerHTML":_vm._s(
						link.title.replaceAll(/(?:\r\n|\r|\n)/g, '<br />')
					)}})])}),1)]):_vm._e(),(
			_vm.props.showTileOverlayText &&
				_vm.props.data.tile_overlay_links &&
				_vm.props.data.tile_overlay_links.length > 0
		)?_c('div',{staticClass:"tileOverlayTextMobile c-championship__tileOverlayTextMobile",staticStyle:{"position":"relative","visibility":"visible"}},[_c('div',{staticClass:"tileOverlayTextWrapper"},_vm._l((_vm.props.data.tile_overlay_links),function(link){return _c(_vm.injections.components.NavigationLink,{key:link.id,tag:"component",attrs:{"_class":"c-championship__url display-block text-decoration-none tileOverlayTextLink","url":link.link,"_external-icon":""}},[_c('span',{domProps:{"innerHTML":_vm._s(
						link.title.replaceAll(/(?:\r\n|\r|\n)/g, '<br />')
					)}})])}),1)]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }